<section class="step-form">
    <div class="container">
       <div class="row align-content-center">
          <div class="col-md-5 px-0 px-md-3">
             <div class="logo-part px-3">
             <div class="form-logo">
                <a routerLink="/">
                   <img src="assets/img/logo.png" class="img-fluid" alt="">
                </a>
             </div>
             <h3 class="text-white" [innerHtml]="pageData.heading"></h3>
             <p [innerHtml]="pageData.description"></p>
          </div>
       </div>

          <div class="col-md-7">
             <a routerLink="/"class="close-step">
                <img src="assets/img/close-ic.png" alt="">
             </a>
             <div class="row mt-md-5 pt-3 justify-content-md-end">
                <div class="col-4 col-md-6 text-md-center">
                   <a routerLink="/search"><img src="assets/img/left-angle.png" alt=""></a>
                </div>
                <div class="col-6">
                   <h4 class="step-head">STEP 2/4</h4>
                </div>
             </div>
             <div class="form-bx">
                <div class="form-cont">
                   <h4>What's the street address?</h4>
                   <form>
                    <div class="mb-3">
                     <label for="exampleInputEmail1" class="form-label">Address</label> <br>
                     <textarea name="" id="address" cols="30" rows="10">{{address}}</textarea>
                  </div>
                  <div class="mb-3">
                     <label for="city" class="form-label">City</label>
                     <input type="text" class="form-control" value="{{city}}" id="city">
                  </div>
                 <div class="mb-3">
                     <label for="zip" class="form-label">ZIP code</label>
                     <input type="text" maxlength="6" class="form-control" value="{{zip}}" id="zip">
                  </div>
               </form>
               <a (click)="saveNext()" style="cursor:pointer" class="form-btn w-100 d-block text-center">Next</a>
            </div>
         </div>
      </div>
   </div>
</div>
</section>