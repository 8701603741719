import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {from, noop, of, Subject} from 'rxjs';
import {map, mergeAll, mergeMap, takeUntil} from 'rxjs/operators';
import { ServiceService } from '../service/service.service';
import Swal from 'sweetalert2';
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit,OnDestroy {

  destroy$ = new Subject();
  pageData:any;

  constructor(private router: Router,private appService:ServiceService,private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    localStorage.removeItem("property_category");
    localStorage.removeItem("property_address");
    localStorage.removeItem("property_city");
    localStorage.removeItem("property_zip");
    localStorage.removeItem("property_price");
    localStorage.removeItem("full_name");
    localStorage.removeItem("email_address"); 
    this.getPageData();
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  submit(){
    $('#submitBtn2').html('Processing...');
    const data = {
      name:$('#name').val(),
      email:$('#email').val(),
      mobile:$('#mobile').val(),
      message:$('#message').val(),
      agent_id:0
    };
    this.appService.postData('contact/save',data).pipe(takeUntil(this.destroy$)).subscribe(res=>{
      var r:any=res;
      if(r.success){
        Swal.fire(
          'Success!',
          r.message,
          'success'
        );
        $('#submitBtn2').html('Submit');
        $('#name').val('');
        $('#email').val('');
        $('#mobile').val('');
        $('#message').val('');
      }else{
        Swal.fire(
          'Error!',
          r.message,
          'error'
        );
        $('#submitBtn2').html('Submit');
      }
    },error =>{
    });
  }
  getPageData(){
    const data = {
      page_id:20
    };
    this.appService.postData('page/get',data).pipe(takeUntil(this.destroy$)).subscribe(res=>{
      var r:any=res;
      this.pageData = r.pageData;
      this.title.setTitle(r.pageData?.seo_title);
      this.meta.updateTag({
          name: 'description',
          content: r.pageData?.seo_description
      });
      this.meta.updateTag({
          name: 'keywords',
          content: r.pageData?.seo_keywords
      });
    },error =>{
    });
  }

}



