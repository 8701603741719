<app-header></app-header>

 <section class="about-listing">
    <div class="container">
       <div class="row align-items-start justify-content-between">
          <div class="col-md-11 col-10">
             <nav class="pt-0 mt-2">
                <div class="nav nav-tabs border-0" id="nav-tab" role="tablist">
                   <button (click)="setCategory(1);" class="nav-link active" id="nav-first-tab" data-bs-toggle="tab" data-bs-target="#nav-first" type="button" role="tab" aria-controls="nav-first" aria-selected="true">Single Family Property Managers</button>
                   <button (click)="setCategory(2);" class="nav-link" id="nav-second-tab" data-bs-toggle="tab" data-bs-target="#nav-second" type="button" role="tab" aria-controls="nav-second" aria-selected="false">Multi-Family Property Managers</button>
                   <button (click)="setCategory(3);" class="nav-link" id="nav-thirld-tab" data-bs-toggle="tab" data-bs-target="#nav-thirld" type="button" role="tab" aria-controls="nav-thirld" aria-selected="false">Association Property Managers</button>
                   <button (click)="setCategory(8);" class="nav-link" id="nav-fourth-tab" data-bs-toggle="tab" data-bs-target="#nav-fourth" type="button" role="tab" aria-controls="nav-fourth" aria-selected="false">Commercial Property Managers</button>
                </div>
             </nav>
          </div>
          <div class="col-md-1 col-2 d-flex justify-content-end">
             <form class="search-container" action="">
                   <input id="search-box" type="text" class="search-box" name="q" />
                   <!-- <span class="search-icon">
                      <img src="assets/img/search-ic.png"alt=""></span> -->
                   <input type="button" id="search-submit" class="search-icon" />
             </form>
          </div>
       </div>
    </div>
    <hr class="mt-0">
    <div class="container">
       <div class="row align-items-start justify-content-between">
          <div class="col-md-12">
             <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade active show" id="nav-first" role="tabpanel" aria-labelledby="nav-first-tab">
                  <div class="row" *ngIf="agents.length > 0">

                      <div class="col-md-4" *ngFor="let agent of agents">
                         <div class="card">
                            <div class="img-box">
                               <img src="{{agent.photo}}" class="img-fluid" alt="">

                               <div *ngIf="agent.featured" class="featured">Featured</div>
                            </div>
                            <div class="card-body">
                               <h4>{{agent.company_name}}</h4>
                               <p>{{agent.address}}, {{city}}, {{state}}</p>
                               <button type="button" class="btn outline-btn">{{agent.category}}</button>
                               <p class="mt-3"><span>{{agent.about}}<a *ngIf="agent.read_more" href="">Read more</a></span></p>
                               <div class="btn-main pt-3">
                                  <a routerLink="/agent/{{state}}/{{city}}/{{agent.user_id}}" class="form-btn text-center d-block">View Detail</a>
                               </div>
                            </div>
                         </div>
                      </div>
                      
                   </div>
                   <div class="row mt-4" *ngIf="agents.length == 0">
                     <div class="col-md-4 text-center mx-auto d-flex justify-content-center">
                        No Record
                     </div>
                  </div>
                   <!-- <div class="row mt-4">
                      <div class="col-md-4 text-center mx-auto d-flex justify-content-center">
                         <div class="btn btn-outline-primary">Load More</div>
                      </div>
                   </div> -->
                </div>
                <div class="tab-pane fade" id="nav-second" role="tabpanel" aria-labelledby="nav-second-tab">
                  <div class="row" *ngIf="agents.length > 0">

                     <div class="col-md-4" *ngFor="let agent of agents">
                        <div class="card">
                           <div class="img-box">
                              <img src="{{agent.photo}}" class="img-fluid" alt="">

                              <div *ngIf="agent.featured" class="featured">Featured</div>
                           </div>
                           <div class="card-body">
                              <h4>{{agent.company_name}}</h4>
                              <p>{{agent.address}}, {{city}}, {{state}}</p>
                              <button type="button" class="btn outline-btn">{{agent.category}}</button>
                              <p class="mt-3"><span>{{agent.about}}<a *ngIf="agent.read_more" href="">Read more</a></span></p>
                              <div class="btn-main pt-3">
                                 <a routerLink="/agent/{{state}}/{{city}}/{{agent.user_id}}" class="form-btn text-center d-block">View Detail</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     
                  </div>
                  <div class="row mt-4" *ngIf="agents.length == 0">
                     <div class="col-md-4 text-center mx-auto d-flex justify-content-center">
                        No Record
                     </div>
                  </div>
                   <!-- <div class="row mt-4">
                      <div class="col-md-4 text-center mx-auto d-flex justify-content-center">
                         <div class="btn btn-outline-primary">Load More</div>
                      </div>
                   </div> -->
                </div>
                <div class="tab-pane fade" id="nav-thirld" role="tabpanel" aria-labelledby="nav-thirld-tab">
                  <div class="row" *ngIf="agents.length > 0">

                     <div class="col-md-4" *ngFor="let agent of agents">
                        <div class="card">
                           <div class="img-box">
                              <img src="{{agent.photo}}" class="img-fluid" alt="">

                              <div *ngIf="agent.featured" class="featured">Featured</div>
                           </div>
                           <div class="card-body">
                              <h4>{{agent.company_name}}</h4>
                              <p>{{agent.address}}, {{city}}, {{state}}</p>
                              <button type="button" class="btn outline-btn">{{agent.category}}</button>
                              <p class="mt-3"><span>{{agent.about}}<a *ngIf="agent.read_more" href="">Read more</a></span></p>
                              <div class="btn-main pt-3">
                                 <a routerLink="/agent/{{state}}/{{city}}/{{agent.user_id}}" class="form-btn text-center d-block">View Detail</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     
                  </div>
                  <div class="row mt-4" *ngIf="agents.length == 0">
                     <div class="col-md-4 text-center mx-auto d-flex justify-content-center">
                        No Record
                     </div>
                  </div>
                   <!-- <div class="row mt-4">
                      <div class="col-md-4 text-center mx-auto d-flex justify-content-center">
                         <div class="btn btn-outline-primary">Load More</div>
                      </div>
                   </div> -->
                </div>
                <div class="tab-pane fade" id="nav-fourth" role="tabpanel" aria-labelledby="nav-fourth-tab">
                  <div class="row" *ngIf="agents.length > 0">

                     <div class="col-md-4" *ngFor="let agent of agents">
                        <div class="card">
                           <div class="img-box">
                              <img src="{{agent.photo}}" class="img-fluid" alt="">

                              <div *ngIf="agent.featured" class="featured">Featured</div>
                           </div>
                           <div class="card-body">
                              <h4>{{agent.company_name}}</h4>
                              <p>{{agent.address}}, {{city}}, {{state}}</p>
                              <button type="button" class="btn outline-btn">{{agent.category}}</button>
                              <p class="mt-3"><span>{{agent.about}}<a *ngIf="agent.read_more" routerLink="/agent/{{state}}/{{city}}/{{agent.user_id}}">Read more</a></span></p>
                              <div class="btn-main pt-3">
                                 <a routerLink="/agent/{{state}}/{{city}}/{{agent.user_id}}" class="form-btn text-center d-block">View Detail</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     
                  </div>
                  <div class="row mt-4" *ngIf="agents.length == 0">
                     <div class="col-md-4 text-center mx-auto d-flex justify-content-center">
                        No Record
                     </div>
                  </div>
                   <!-- <div class="row mt-4">
                      <div class="col-md-4 text-center mx-auto d-flex justify-content-center">
                         <div class="btn btn-outline-primary">Load More</div>
                      </div>
                   </div> -->
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>
 <app-newsletter></app-newsletter>
 <hr>
<app-footer></app-footer>
