<section class="step-form">
    <div class="container">
       <div class="row align-content-center">
          <div class="col-md-5 px-0 px-md-3">
             <div class="logo-part px-3">
             <div class="form-logo">
                <a routerLink="/">
                   <img src="assets/img/logo.png" class="img-fluid" alt="">
                </a>
             </div>
             <h3 class="text-white" [innerHtml]="pageData?.heading"></h3>
             <p [innerHtml]="pageData?.description"></p>
          </div>
       </div>

          <div class="col-md-7">
             <a routerLink="/" class="close-step">
                   <img src="assets/img/close-ic.png" alt="">
                </a>
                <div class="row mt-md-5 pt-3 justify-content-md-end">
                <div class="col-4 ps-4 col-md-6 text-md-center">
                    <a *ngIf="questionIndex==0" routerLink="/search-three"><img src="assets/img/left-angle.png" alt=""></a>
                    <a *ngIf="questionIndex > 0" style="cursor:pointer" (click)="PreviousQuestion()"><img src="assets/img/left-angle.png" alt=""></a>
                </div>
                <div class="col-6">
                   <h4 class="step-head">STEP 4/4</h4>
                </div>
             </div>
             <div class="form-bx mt-md-5">
                <!-- {{this.examQuestions | json}} -->
                <div class="form-cont">
                
                     <h4>{{this.question}}</h4>
                     <ul class="list-unstyled ps-0 my-4" *ngIf="questionType == 'single'">
                        <li *ngIf="this.option_1" class="d-flex align-items-center justify-content-between p-category" id="option_1" (click)="selectPrice(this.option_1,'option_1')">
                           <a>
                           &nbsp; {{this.option_1}} </a>
                        </li>
                        <li *ngIf="this.option_2" class="d-flex align-items-center justify-content-between p-category" id="option_2" (click)="selectPrice(this.option_2,'option_2')">
                           <a>
                            &nbsp; {{this.option_2}} </a>
                        </li>
                        <li *ngIf="this.option_3" class="d-flex align-items-center justify-content-between p-category" id="option_3" (click)="selectPrice(this.option_3,'option_3')">
                           <a>
                            &nbsp; {{this.option_3}} </a>
                        </li>
                        <li *ngIf="this.option_4" class="d-flex align-items-center justify-content-between p-category" id="option_4" (click)="selectPrice(this.option_4,'option_4')">
                           <a>
                            &nbsp; {{this.option_4}} </a>
                        </li>
                        <li *ngIf="this.option_5" class="d-flex align-items-center justify-content-between p-category" id="option_5" (click)="selectPrice(this.option_5,'option_5')">
                           <a>
                            &nbsp; {{this.option_5}} </a>
                        </li>
                        <li *ngIf="this.option_6" class="d-flex align-items-center justify-content-between p-category" id="option_6" (click)="selectPrice(this.option_6,'option_6')">
                           <a>
                            &nbsp; {{this.option_6}} </a>
                        </li>
                     </ul>
                     <ul class="list-unstyled ps-0 my-4" *ngIf="questionType == 'multi'">
                        <li *ngIf="this.option_1" class="d-flex align-items-center justify-content-between p-category" id="option_1" (click)="selectMulti(this.option_1,'option_1')">
                           <a>
                           &nbsp; {{this.option_1}} </a>
                        </li>
                        <li *ngIf="this.option_2" class="d-flex align-items-center justify-content-between p-category" id="option_2" (click)="selectMulti(this.option_2,'option_2')">
                           <a>
                            &nbsp; {{this.option_2}} </a>
                        </li>
                        <li *ngIf="this.option_3" class="d-flex align-items-center justify-content-between p-category" id="option_3" (click)="selectMulti(this.option_3,'option_3')">
                           <a>
                            &nbsp; {{this.option_3}} </a>
                        </li>
                        <li *ngIf="this.option_4" class="d-flex align-items-center justify-content-between p-category" id="option_4" (click)="selectMulti(this.option_4,'option_4')">
                           <a>
                            &nbsp; {{this.option_4}} </a>
                        </li>
                        <li *ngIf="this.option_5" class="d-flex align-items-center justify-content-between p-category" id="option_5" (click)="selectMulti(this.option_5,'option_5')">
                           <a>
                            &nbsp; {{this.option_5}} </a>
                        </li>
                        <li *ngIf="this.option_6" class="d-flex align-items-center justify-content-between p-category" id="option_6" (click)="selectMulti(this.option_6,'option_6')">
                           <a>
                            &nbsp; {{this.option_6}} </a>
                        </li>
                     </ul>
                     <div *ngIf="questionType == 'fill_blank'" class="mb-3">
                        <textarea class="form-control" id="fill_blank" (keyup)="setValue()" style="width:370px" ></textarea>
                     </div>
                 
                   
                   <a (click)="NextQuestion();" id="submitBtn" style="cursor:pointer" class="form-btn w-100 d-block text-center">Next</a>
                </div>

             </div>
          </div>
       </div>
    </div>
 </section>