<section class="step-form">
    <div class="container">
       <div class="row align-content-center">
          <div class="col-md-5 px-0 px-md-3">
             <div class="logo-part px-3">
                <div class="form-logo">
                <a routerLink="/">
                   <img src="assets/img/logo.png" class="img-fluid" alt="">
                </a>
             </div>
             <h3 class="text-white" [innerHtml]="pageData.heading"></h3>
             <p [innerHtml]="pageData.description"></p>
             </div>
          </div>

          <div class="col-md-7">
             <a routerLink="/" class="close-step">
                   <img src="assets/img/close-ic.png" alt="">
                </a>
             <div class="form-bx mt-md-5">
                
                <div class="form-cont">
                   <h4>What type of property do you own?</h4>
                   <ul class="list-unstyled ps-0 my-4">
                      <li class="d-flex align-items-center justify-content-between p-category selected" id="single_family" (click)="selectCategory('single_family',1)">
                         <a>
                         <img src="assets/img/home-ic.png" width="23" alt=""> &nbsp; Single Family </a>
                         <!-- <input type="radio" name="typeOfProperty"> -->
                      </li>
                      <li class="d-flex align-items-center justify-content-between p-category" id="multi_family" (click)="selectCategory('multi_family',2)">
                         <a>
                         <img src="assets/img/home-ic2.png" width="23" alt=""> &nbsp; Multi Family </a>
                         <!-- <input type="radio" name="typeOfProperty"> -->
                      </li>
                      <li class="d-flex align-items-center justify-content-between p-category" id="association" (click)="selectCategory('association',3)">
                         <a>
                         <img src="assets/img/home-ic3.png" width="23" alt=""> &nbsp; Association </a>
                         <!-- <input type="radio" name="typeOfProperty"> -->
                      </li>
                      <li class="d-flex align-items-center justify-content-between p-category" id="commercial" (click)="selectCategory('commercial',8)">
                         <a>
                         <img src="assets/img/home-ic4.png" width="23" alt=""> &nbsp; Commercial </a>
                         <!-- <input type="radio" name="typeOfProperty"> -->
                      </li>
                   </ul>
                   <a routerLink="/search-two" class="form-btn w-100 d-block text-center">Next</a>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>