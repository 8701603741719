<section class="step-form">
    <div class="container">
      <div class="row align-content-center">
          <div class="col-md-5 px-0 px-md-3">
             <div class="logo-part px-3">
             <div class="form-logo">
                <a routerLink="/">
                   <img src="assets/img/logo.png" class="img-fluid" alt="">
                </a>
             </div>
             <h3 class="text-white" [innerHtml]="pageData.heading"></h3>
             <p [innerHtml]="pageData.description"></p>
          </div>
       </div>

          <div class="col-md-7">
             <a routerLink="/" class="close-step">
                <img src="assets/img/close-ic.png" alt="">
             </a>
             <div class="row mt-md-5 pt-3 justify-content-md-end">
                <div class="col-4 col-md-6 text-md-center">
                    <a routerLink="/search-two"><img src="assets/img/left-angle.png" alt=""></a>
                </div>
                <div class="col-6 ">
                   <h4 class="step-head">STEP 3/4</h4>
                </div>
             </div>
             <div class="form-bx">
                <div class="form-cont">
                   <h4>What's your contact information?</h4>
             <form>
                  <div class="mb-3">
                     <label for="Name" class="form-label">Full Name</label>
                     <input type="text" value="{{full_name}}" class="form-control bg-transparent" id="full_name">
                  </div>
                 <div class="mb-3">
                     <label for="Email" class="form-label">Email Id</label>
                     <input type="email" value="{{email_address}}" class="form-control bg-transparent" id="email_address">
                  </div>
                  <div class="mb-3">
                     <label for="Email" class="form-label">Phone No.</label>
                     <input type="text" value="{{phone_no}}" maxlength="10" class="form-control bg-transparent" id="phone_number">
                  </div>
               </form>
               <a (click)="saveNext()" style="cursor:pointer" class="form-btn w-100 d-block text-center">Next</a>
            </div>
         </div>
      </div>
   </div>
</div>
</section>
<!-- data-bs-backdrop='static' -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
     <div class="modal-body p-0 border-round">
      <div class="model-top" style="position:relative">
         <h4>No More Spam: Our Quote Guarantee</h4>
         <p class="mb-0">Tired of endless calls and emails after requesting a property management quote? We get it. That's why we offer our Quote Guarantee.</p>
         <a class="close-btn" id="closeBtn" data-bs-dismiss="modal" aria-label="Close">X</a>
      </div>
      <div class="model-bottom">
         <div class="row align-items-center">
            <div class="col-8">
             <h4 class="text-white mb-0">Name Your Price</h4>
          </div>
          <div class="col-4">
           <!-- <div class="cont">
            <div class="toggle">
              <input type="checkbox" id="mode-toggle" class="toggle__input">
              <label for="mode-toggle" class="toggle__label"></label>
           </div>
        </div> -->
     </div>
  </div>
  <div class="row">
     <div class="col-md-12">
        <label for="number" class="form-label input-label">Mobile Number</label>
        <input type="text" class="form-control" maxlength="10" id="property_number" aria-describedby="number">
     </div>
  </div>
  <div class="row ">
     <div class="col-md-12 ">         
     <form class="range-form">
      <div class="form-group row align-items-start mx-0">
       <div class="col-9 px-0">
        <label for="formControlRange" class="input-label">Price</label>
        <input type="range" min="100" max="150" value="0" (change)="showValue()" class="form-control-range range-slider" id="myRange">
        <div class="d-flex align-items-center justify-content-between">
           <p>$ 100</p>
           <p>$ 150</p>
        </div>
     </div>
     <div class="col-3 pe-0">
        <span id="demo">$100</span>
     </div>
  </div>
  </form>
  </div>
  </div>
  
  <div class="my-3">
   <div class="row ">
      <div class="col-md-12"><button (click)="saveLead()" id="submitBtn" class="form-btn w-100">Submit</button></div>
      <!-- <div class="col-md-6"><button id="submitBtn" data-bs-dismiss="modal" aria-label="Close" class="cancel-btn w-100">Cancel</button></div> -->
   </div>
     
     
  </div>
  </div>
  </div>
  
  </div>
  </div>
  </div>