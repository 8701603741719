
<app-header-small></app-header-small>

<section class="section-padding subscribe contact-form">
    <div class="container">
     <div class="row">
        <div class="col-md-12 col-lg-10 mx-auto text-start">
              <div class="section-heading text-center">
                 <h3 class="text-white">Get In <span>Touch</span></h3>
                 <p [innerHTML]="pageData?.description"></p>
              </div>
           <div class="subscribe-box p-md-5 text-start">
              <form class="row mt-4">
                 <div class="mb-3 col-md-4">
                   <label for="name" class="form-label text-white">Full Name</label>
                   <input type="text" class="form-control" id="name">
                </div>
                <div class="mb-3 col-md-4">
                   <label for="email" class="form-label text-white">Email Address</label>
                   <input type="email" class="form-control" id="email" aria-describedby="emailHelp">
                </div>
    
                <div class="mb-3 col-md-4">
                   <label for="mobile" class="form-label text-white">Phone Number</label>
                   <input type="text" maxlength="10" class="form-control" id="mobile">
                </div>
                <div class="mb-3 col-md-12">
                   <label for="message" class="form-label text-white">Message</label> <br>
                   <textarea style="padding:10px" name="" id="message" class="w-100"></textarea>
                </div>
    
                <div class="col-md-3 ">
                   <button type="button" id="submitBtn2" (click)="submit()" class="form-btn w-100">Submit</button>
                </div>
             </form>
          </div>
       </div>
    </div>
    </div>
    </section>



<hr>
<app-footer></app-footer>