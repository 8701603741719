<div class="modal fade" id="signupModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
     <div class="modal-content">
      <div class="modal-body p-0 border-round">
       <div class="model-top" style="position:relative">
          <h4>Join our Network</h4>
          <p class="mb-0">Tired of endless calls and emails after requesting a property management quote? We get it. That's why we offer our Quote Guarantee.</p>
          <a class="close-btn2" id="closeBtn2" data-bs-dismiss="modal" aria-label="Close">X</a>
       </div>
       <div class="model-bottom">
        <div class="row">
            <div class="col-md-12">
                <label for="number" class="form-label input-label2">Company Name</label>
                <input type="text" class="form-control" id="s_company_name">
            </div>
            <div class="col-md-12">
            <label for="number" class="form-label input-label2">Email Address</label>
            <input type="text" class="form-control" id="s_email_address">
            </div>
            <div class="col-md-12">
            <label for="number" class="form-label input-label2">Mobile Number</label>
            <input type="text" class="form-control" id="s_mobile_no">
            </div>
        </div>
   
    <div class="my-3 mt-4">
        <div class="row ">
        <div class="col-md-12"><button id="registerBtn" (click)="register()" class="form-btn w-100">Submit</button></div>
        </div>
    </div>
   </div>
   </div>
   
   </div>
   </div>
   </div>