
<app-header-small></app-header-small>

 <section class="section-padding blog" >
 <div class="container">
  <div class="row">
    <div class="section-heading">
       <h3><span>Blog Details</span></h3>
    </div>
 </div>
 <div class="row">
    <div class="col-md-12">
       <div class="card">
          <img src="{{blogDetails.banner}}" class="img-fluid" alt="">
          <div class="card-body">
             <p><span>{{blogDetails.post_date}}</span></p>
             <h4>{{blogDetails.heading}}</h4>
             <p>{{blogDetails.description}}</p>
          </div>
       </div>
    </div>

 </div>
</div>
</section> 
<app-newsletter></app-newsletter>
<hr>
<app-footer></app-footer>