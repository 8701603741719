
<app-header-small></app-header-small>

<section class="section-padding blog" *ngIf="faqs.length > 0">
 <div class="container">
  <div class="row">
    <div class="section-heading">
       <h3><span>FAQs</span></h3>
       <p>Because knowledge is meant for sharing</p>
    </div>
 </div>
 <div class="row">
    <div class="col-md-12" *ngFor="let faq of faqs">
       <div class="card">
        
          <div class="card-body">
             <h4>{{faq.question}}</h4>
             <p>{{faq.answer}}</p>
          </div>
       </div>
    </div>

 </div>
 <div class="row" *ngIf="loading">
   <div class="col-md-12">
      <div class="card">
         <div class="card-body">
            Loading....
         </div>
      </div>
   </div>
 </div>
</div>
</section>
<app-newsletter></app-newsletter>
<hr>
<app-footer></app-footer>