import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {from, noop, of, Subject} from 'rxjs';
import {map, mergeAll, mergeMap, takeUntil} from 'rxjs/operators';
import { ServiceService } from '../service/service.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-search-three',
  templateUrl: './search-three.component.html',
  styleUrls: ['./search-three.component.css']
})
export class SearchThreeComponent implements OnInit {

  destroy$ = new Subject();
  pageData:any;
  questions:any = [];
  page = 1;
  questionIndex = 0;
  question:any;
  questionType:any;
  option_1:any;
  option_2:any;
  option_3:any;
  option_4:any;
  option_5:any;
  option_6:any;
  examQuestions:any = [];
  total = 0;
  multipleAnswer:any = [];

  constructor(private router: Router,private appService:ServiceService) { }

  ngOnInit(): void {
    localStorage.setItem('property_price','300');
    this.getPageData();
    this.questionList();
  }

  selectPrice(type:any,id:any){
    $('.p-category').removeClass('selected'); 
    $('#'+id).addClass('selected'); 
    this.examQuestions[this.questionIndex].answer = type;
  }
  setValue(){
    this.examQuestions[this.questionIndex].answer = $('#fill_blank').val();
  }
  selectMulti(type:any,id:any){
    if($('#'+id).hasClass('selected')){
      $('#'+id).removeClass('selected'); 
      const index = this.multipleAnswer.findIndex(function(selectedRow:any) {
        return selectedRow == type
      });
      //const index = this.invoiceRow.indexOf(invoiceID);
      if (index > -1) { // only splice array when item is found
        this.multipleAnswer.splice(index, 1); // 2nd parameter means remove one item only
      }
    }else{
      $('#'+id).addClass('selected');
      this.multipleAnswer.push(type);
    }
    this.examQuestions[this.questionIndex].answer = this.multipleAnswer;
  } 
  NextQuestion(){
    if(this.examQuestions[this.questionIndex].answer == ''){
      Swal.fire(
        'Error!',
        'Please select answer.',
        'error'
      );
      return false;
    }
    var newKey = this.questionIndex+1;
    this.questionIndex = newKey;
    if(this.questionIndex <= (this.total-1)){

      this.question = this.questions[this.questionIndex].question;
      this.questionType = this.questions[this.questionIndex].type;
      this.option_1 = this.questions[this.questionIndex].option_1;
      this.option_2 = this.questions[this.questionIndex].option_2;
      this.option_3 = this.questions[this.questionIndex].option_3;
      this.option_4 = this.questions[this.questionIndex].option_4;
      this.option_5 = this.questions[this.questionIndex].option_5;
      this.option_6 = this.questions[this.questionIndex].option_6; 
      this.examQuestions[this.questionIndex].answer = '';
      this.multipleAnswer = [];
      $('.p-category').removeClass('selected'); 
    }else{
      this.saveLead();
    }
  }
  PreviousQuestion(){
    var newKey = this.questionIndex-1;
    this.questionIndex = newKey;
    if(this.questionIndex <= (this.total-1)){
      this.question = this.questions[this.questionIndex].question;
      this.questionType = this.questions[this.questionIndex].type;
      this.option_1 = this.questions[this.questionIndex].option_1;
      this.option_2 = this.questions[this.questionIndex].option_2;
      this.option_3 = this.questions[this.questionIndex].option_3;
      this.option_4 = this.questions[this.questionIndex].option_4;
      this.option_5 = this.questions[this.questionIndex].option_5;
      this.option_6 = this.questions[this.questionIndex].option_6; 
      this.examQuestions[this.questionIndex].answer = '';
      this.multipleAnswer = [];
      $('.p-category').removeClass('selected'); 
    }else{
      alert('Bus Kar');
    }
  }
  questionList(){
    const data = {
      type:localStorage.getItem("property_category")
    };
    this.appService.postData('question/list',data).pipe(takeUntil(this.destroy$)).subscribe(res=>{
      var r:any=res;
      this.questions = r.questions;
      this.question = this.questions[this.questionIndex].question;
      this.questionType = this.questions[this.questionIndex].type;
      this.option_1 = this.questions[this.questionIndex].option_1;
      this.option_2 = this.questions[this.questionIndex].option_2;
      this.option_3 = this.questions[this.questionIndex].option_3;
      this.option_4 = this.questions[this.questionIndex].option_4;
      this.option_5 = this.questions[this.questionIndex].option_5;
      this.option_6 = this.questions[this.questionIndex].option_6;
      this.total = this.questions.length;
      this.examQuestions = [];
      this.questions.forEach(role => this.examQuestions.push({question:role.question,answer:''}));
     

    },error =>{
    });
  }
  getPageData(){
    const data = {
      page_id:7
    };
    this.appService.postData('page/get',data).pipe(takeUntil(this.destroy$)).subscribe(res=>{
      var r:any=res;
      this.pageData = r.pageData;
    },error =>{
    });
  }
  saveLead(){
    var nitam = 0;
    if(nitam == 0){
      $('#submitBtn').html('Processing...');
      const data = {
        property_category:localStorage.getItem("property_category"),
        property_address:localStorage.getItem("property_address"),
        property_city:localStorage.getItem("property_city"),
        property_zip:localStorage.getItem("property_zip"),
        property_price:'',
        full_name:localStorage.getItem("full_name"),
        email_address:localStorage.getItem("email_address"),
        property_number:localStorage.getItem("phone_no"),
        price:'',
        exam:this.examQuestions
      };
        this.appService.postData('lead/save',data).pipe(takeUntil(this.destroy$)).subscribe(res=>{
          var r:any=res;
          if(r.success){
            /* Swal.fire(
              'Success!',
              r.message,
              'success'
            ); */
            
            this.router.navigateByUrl('/thank-you');
          }else{
            Swal.fire(
              'Error!',
              r.message,
              'error'
            );
            $('#submitBtn').html('Submit');
          }
        },error =>{
        });
    }
    
  }

}
function obj(obj: any, arg1: (value: any, key: any) => void) {
  throw new Error('Function not implemented.');
}

