<header>
    <div class="ban-img">
       <img src="assets/img/ban-bg.png" class="img-fluid" alt="">
    </div>
    <div class="top-header text-center" style="position:relative;" id="topAnnouncement" *ngIf="topBarData.status == 1">
       <div class="container">
          <div class="row align-items-center justify-content-between">
             <div class="col-md-9">
                <h4 class="text-start">{{topBarData.heading}}</h4>
                <p [innerHTML]="topBarData.description" class="mb-0 text-start"></p>
             </div>
             <div class="col">
                <div class="close-btn">
                   <a style="cursor:pointer" (click)="closeAnnouncement()"><img src="assets/img/close-ic.png" alt=""></a>
                </div>
             </div>
          </div>
       </div>
    </div>
    <nav>
       <div class="container">
          <div class="row align-items-center position-relative">
             <a href="" class="menu-nav" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" style="display: none;">
                <span>
                   <img src="assets/img/menu-bar.png" width="30" alt="">
                </span>
             </a>
             <div class="col-md-3 col-lg-2 d-flex align-items-center justify-content-between">
                <div class="logo">
                   <a routerLink="/" title="logo"> 
                    <img src="assets/img/logo.png" class="img-fluid" width="230" alt="">                    
                 </a>
              </div>

           </div>
           <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
              <div class="offcanvas-header">                  
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
             </div>
             <div class="offcanvas-body">
                <ul class="ps-0 list-unstyled text-dark">
                  <li class="my-2"><a routerLink="/" class="active">Home</a></li>
                      <li class="my-2"><a routerLink="/resources">Resources</a></li>
                      <li class="my-2"><a routerLink="/about-us">About Us</a></li>
                      <li class="my-2"><a routerLink="/blogs">Blog</a></li>
                      <li class="my-2"><a routerLink="/faqs">FAQ’s</a></li>
               </ul>
               <div class="button-box d-flex align-items-center justify-content-lg-end">
            <div class="btns">
               <a href="https://propertifi.online/agent-panel/" target="_blank" class="form-btn">Sign In</a>
            </div>
            <div class="btns lightblue">
               <a style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#signupModal">Join Our Network &#x27F6;</a>
            </div>
         </div>
            </div>
         </div>
         <div class="col-lg-6 col-md-2">
          <div class="main-menu d-flex justify-content-center">
             <ul class="nav">
               <li class="my-2"><a routerLink="/" class="active">Home</a></li>
               <li class="my-2"><a routerLink="/resources">Resources</a></li>
               <li class="my-2"><a routerLink="/about-us">About Us</a></li>
               <li class="my-2"><a routerLink="/blogs">Blog</a></li>
               <li class="my-2"><a routerLink="/faqs">FAQ’s</a></li>
             </ul>
             
          </div>
       </div>
       <div class="col-lg-4 col-md-7">
          <div class="button-box desktop d-flex align-items-center justify-content-lg-end">
            <div class="btns">
               <a href="https://propertifi.online/agent-panel/" target="_blank">Sign In</a>
            </div>
            <div class="btns lightblue">
               <a style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#signupModal">Join Our Network &#x27F6;</a>
            </div>
         </div>
      </div>
   </div>
</div>
</nav>

<section class="banner">
 <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 col-lg-7">
       <div class="ban-content">
          <h3>Get Your 🏡 <span>Property</span> <br class="d-md-block d-none"> Managed Today</h3>
          <p>{{pageData?.description}}</p>
       </div>
       <div class="form-box">
         <a routerLink="/search" class="form-btn">Get Started</a>
          <!-- <div class="card">
             <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                   <button class="nav-link active" id="nav-Single-tab" data-bs-toggle="tab" data-bs-target="#nav-Single" type="button" role="tab" aria-controls="nav-Single" aria-selected="true">Single Family</button>
                   <button class="nav-link" id="nav-Multi-tab" data-bs-toggle="tab" data-bs-target="#nav-Multi" type="button" role="tab" aria-controls="nav-Multi" aria-selected="false">Multi Family</button>
                   <button class="nav-link" id="nav-Association-tab" data-bs-toggle="tab" data-bs-target="#nav-Association" type="button" role="tab" aria-controls="nav-Association" aria-selected="false">Association</button>
                   <button class="nav-link" id="nav-Commercial-tab" data-bs-toggle="tab" data-bs-target="#nav-Commercial" type="button" role="tab" aria-controls="nav-Commercial" aria-selected="false">Commercial</button>
                </div>
             </nav>
             <div class="tab-content px-3 py-2" id="nav-tabContent">
                <div class="tab-pane fade active show" id="nav-Single" role="tabpanel" aria-labelledby="nav-Single-tab">
                   <form action="" class="d-flex align-items-center justify-content-between">
                      <input type="text" placeholder="Enter your address and zip code">
                      <a routerLink="/search" class="form-btn">Search</a>
                   </form>
                </div>
                <div class="tab-pane fade" id="nav-Multi" role="tabpanel" aria-labelledby="nav-Multi-tab">
                   <form action="" class="d-flex align-items-center justify-content-between">
                      <input type="text" placeholder="Enter your address and zip code">
                      <a routerLink="/search" class="form-btn">Search</a>
                   </form>
                </div>
                <div class="tab-pane fade" id="nav-Association" role="tabpanel" aria-labelledby="nav-Association-tab">
                   <form action="" class="d-flex align-items-center justify-content-between">
                      <input type="text" placeholder="Enter your address and zip code">
                      <a routerLink="/search" class="form-btn">Search</a>
                   </form>
                </div>
                <div class="tab-pane fade" id="nav-Commercial" role="tabpanel" aria-labelledby="nav-Commercial-tab">
                   <form action="" class="d-flex align-items-center justify-content-between">
                      <input type="text" placeholder="Enter your address and zip code">
                      <a routerLink="/search" class="form-btn">Search</a>
                   </form>
                </div>
             </div>
          </div> -->
       </div>
    </div>
 </div>
</div>
</section>
</header>
<app-sign-up-popup></app-sign-up-popup>