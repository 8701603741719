<section class="section-padding subscribe">
    <div class="container">
       <div class="subscribe-box">
          <div class="row">
             <div class="col-md-9 mx-auto text-center ">
                <div class="section-heading ">
                   <h3 class="text-white">Get <span>Exclusive Insights</span> on Directly to Your Inbox!</h3>
                   <p>Subscribe to our newsletter, articles, resources, and events</p>
                </div>
                <div class="subs-box d-flex align-items-center justify-content-center">
                   <input type="text" placeholder="Email address" id="newsletter_email" class="border-0">
                   <button type="button" (click)="saveNewsletter()" id="submitBtn" class="submit-btn">Subscribe</button>
                </div>
             </div>
          </div>
       </div>
    </div>
   </section>
   