import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {from, noop, of, Subject} from 'rxjs';
import {map, mergeAll, mergeMap, takeUntil} from 'rxjs/operators';
import { ServiceService } from '../service/service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sign-up-popup',
  templateUrl: './sign-up-popup.component.html',
  styleUrls: ['./sign-up-popup.component.css']
})
export class SignUpPopupComponent implements OnInit,OnDestroy {

  destroy$ = new Subject();
  full_name = localStorage.getItem('full_name');
  email_address = localStorage.getItem('email_address');
  pRange:any = 100;

  constructor(private router: Router,private appService:ServiceService) { }

  ngOnInit(): void {
    
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  register(){
      $('#registerBtn').html('Processing...');
      const data = {
        company_name:$('#s_company_name').val(),
        email:$('#s_email_address').val(),
        mobile:$('#s_mobile_no').val(),
      };
        this.appService.postData('agent/save',data).pipe(takeUntil(this.destroy$)).subscribe(res=>{
          var r:any=res;
          if(r.success){
            
            $('#registerBtn').html('Submit');
            $('#closeBtn2').trigger('click');
            $('.modal-backdrop').remove();
            Swal.fire(
              'Success!',
              r.message,
              'success'
            ); 
          }else{
            Swal.fire(
              'Error!',
              r.message,
              'error'
            );
            $('#submitBtn').html('Submit');
          }
        },error =>{
        });
   
    
  }

}


