
<app-header-small></app-header-small>

<section class="state section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
              <div class="section-heading">
                <h3>Landlord <span>Tenant Rights</span></h3>
                <div  [innerHTML]="pageData?.description">
                 <p class="my-3">Loading...</p>
                </div>
           </div>
        </div>
     </div>
       <div class="row mt-3">
          <div class="col-md-12 px-lg-4">
             <div class="main-box">
                <ul class="state-select list-unstyled d-flex flex-wrap mb-0">
                   <li *ngFor="let state of states; let i = index"><a routerLink="/law/{{state.slug}}"><p class="common-state-class" [id]="'state_'+state.id">{{state.state}}</p></a></li>
                </ul>
             </div>
          </div>
       </div>
    </div>
   </section>

<app-newsletter></app-newsletter>
<hr>
<app-footer></app-footer>