
<app-header-small></app-header-small>
<section class="section-padding about d-flex flex-wrap position-relative">
 <div class="container">
    <div class="row">
       <div class="col-lg-12">
         <div class="section-heading">
           <h3>Acceptable Use <span>Policy</span></h3>
           <div  [innerHTML]="pageData?.description">
            <p class="my-3">Loading...</p>
           </div>
      </div>
   </div>
</div>
</div>
</section>
<app-newsletter></app-newsletter>
<hr>
<app-footer></app-footer>