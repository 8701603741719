<header class="pb-0">
    <nav>
       <div class="container">
          <div class="row align-items-center position-relative">
             <a href="" class="menu-nav" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" style="display: none;">
                <span>
                   <img src="/assets/img/menu-bar.png" width="30" alt="">
                </span>
             </a>
             <div class="col-md-3 col-lg-2 d-flex align-items-center justify-content-between">
                <div class="logo">
                   <a routerLink="/" title="logo">
                      <img src="assets/img/logo.png" class="img-fluid" width="230" alt="">
                   </a>
                </div>
             </div>
             <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <div class="offcanvas-header">
                   <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                   <ul class="ps-0 list-unstyled text-dark">
                    <li class="my-2"><a routerLink="/" class="active">Home</a></li>
                    <li class="my-2"><a routerLink="/resources">Resources</a></li>
                    <li class="my-2"><a routerLink="/about-us">About Us</a></li>
                    <li class="my-2"><a routerLink="/blogs">Blog</a></li>
                    <li class="my-2"><a routerLink="/faqs">FAQ’s</a></li>
                   </ul>
                   <div class="button-box d-flex align-items-center justify-content-lg-end">
                      <div class="btns">
                         <a href="https://propertifi.online/agent-panel/" class="form-btn">Sign In</a>
                      </div>
                      <div class="btns lightblue">
                         <a style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#signupModal">Join Our Network &#x27F6;</a>
                      </div>
                   </div>
                </div>
             </div>
             <div class="col-lg-6 col-md-2">
                <div class="main-menu d-flex justify-content-center">
                   <ul class="nav">
                    <li class="my-2"><a routerLink="/" class="active">Home</a></li>
                    <li class="my-2"><a routerLink="/resources">Resources</a></li>
                    <li class="my-2"><a routerLink="/about-us">About Us</a></li>
                    <li class="my-2"><a routerLink="/blogs">Blog</a></li>
                    <li class="my-2"><a routerLink="/faqs">FAQ’s</a></li>
                   </ul>
                   
                </div>
             </div>
             <div class="col-lg-4 col-md-7">
                <div class="button-box desktop d-flex align-items-center justify-content-lg-end">
                   <div class="btns">
                      <a href="https://propertifi.online/agent-panel/">Sign In</a>
                   </div>
                   <div class="btns lightblue">
                      <a style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#signupModal">Join Our Network &#x27F6;</a>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </nav>
    <div class="container mt-4">
     <div class="row pagination">
       <div class="col-md-10">
          <ul class="list-unstyled d-flex align-items-center ">
             <li><a href="" class="text-white">Home &nbsp; &nbsp; >  &nbsp; &nbsp;</a></li>
             <li><a href="" class="text-white">&nbsp; &nbsp;{{state}} &nbsp; &nbsp; >&nbsp; &nbsp;</a></li>
             <li><a href="" class="text-white select">&nbsp; &nbsp;{{city}}&nbsp; &nbsp;</a></li>
          </ul>
       </div>
    </div>

    <div class="row mt-2">
       <div class="col-md-8 d-flex align-items-start">
          <div class="logo-img me-3">
             <img src="{{agentData.photo}}" alt="">
          </div>

          <div *ngIf="agentData.featured" class="featured position-unset rounded">Featured</div>
       </div>
    </div>
 </div>
</header>
<section class="section-padding details-page">
<div class="container">
  <div class="row">
     <div class="col-md-12">
        <h3 class="mt-4">{{agentData.company_name}}</h3>
        <p>{{agentData.address}}, {{city}}, {{state}}</p>
        <p>{{agentData.about}}</p>
     </div>
  </div>
</div>
</section>
<section class="section-padding subscribe contact-form">
<div class="container">
 <div class="row">
    <div class="col-md-12 col-lg-10 mx-auto text-start">
          <div class="section-heading text-center">
             <h3 class="text-white">Get In <span>Touch</span></h3>
             <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro labore veritatis <br> molestiae autem, libero earum.</p>
          </div>
       <div class="subscribe-box p-md-5 text-start">
          <form class="row mt-4">
             <div class="mb-3 col-md-4">
               <label for="name" class="form-label text-white">Full Name</label>
               <input type="text" class="form-control" id="name">
            </div>
            <div class="mb-3 col-md-4">
               <label for="email" class="form-label text-white">Email Address</label>
               <input type="email" class="form-control" id="email" aria-describedby="emailHelp">
            </div>

            <div class="mb-3 col-md-4">
               <label for="mobile" class="form-label text-white">Phone Number</label>
               <input type="text" maxlength="10" class="form-control" id="mobile">
            </div>
            <div class="mb-3 col-md-12">
               <label for="message" class="form-label text-white">Message</label> <br>
               <textarea name="" style="padding:10px" id="message" class="w-100"></textarea>
            </div>

            <div class="col-md-3 ">
               <button type="button" id="submitBtn2" (click)="submit()" class="form-btn w-100">Submit</button>
            </div>
         </form>
      </div>
   </div>
</div>
</div>
</section>
<app-sign-up-popup></app-sign-up-popup>

<app-footer></app-footer>