<footer class="section-padding pb-0">
    <div class="container">
       <div class="row">
          <div class="col  my-3">
             <div class="logo mb-3">
                <a href=""><img src="assets/img/footer-logo.png" width="150" alt=""></a>
             </div>
             <p>© Propertifi, Inc. <br> Copyright © 2024. All rights reserved.</p>
          </div>
          <div class="col  my-3">
             <h4><strong>For Property Owners</strong></h4>
             <ul class="list-unstyled">
                <li><a routerLink="/faqs"><p>FAQ</p></a></li>
                <li><a routerLink="/blogs"><p>Blog</p></a></li>
                <li><a routerLink="/resources"><p>Resources</p></a></li>
                <li><a routerLink="/property-management"><p>Property Management</p></a></li>
             </ul>
          </div>
   
          <div class="col  my-3">
             <h4><strong>For Property Managers</strong></h4>
   
             <ul class="list-unstyled">
                <li><a style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#signupModal"><p>Join our Network</p></a></li>
                <li><a routerLink="/resource-center"><p>Resource Center</p></a></li>
                <li><a routerLink="/my-client-center"><p>My Client Center</p></a></li>
             </ul>
          </div>
   
          <div class="col  my-3">
             <h4><strong>Company</strong></h4>
             <ul class="list-unstyled">
                <li><a routerLink="/about-us"><p>About Us</p></a></li>
                <li><a routerLink="/our-mission"><p>Our Mission</p></a></li>
                <li><a routerLink="/career"><p>Career</p></a></li>
             </ul>
          </div>
          <div class="col  my-3">
             <h4><strong>Support</strong></h4>
             <ul class="list-unstyled">
                <li><a routerLink="/contact-us"><p>Contact Us</p></a></li>
                <li><a routerLink="/privacy-policy"><p>Privacy Policy</p></a></li>
                <li><a routerLink="/acceptable-use-policy"><p>Acceptable Use Policy</p></a></li>
                <li><a routerLink="/limited-use-disclosure"><p>Limited Use Disclosure</p></a></li>
             </ul>
          </div>
          <div class="col  my-3">
             <h4><strong>Keep in Touch</strong></h4>
             <ul class="list-unstyled">
                <li>
                   <a href="" class="d-flex align-items-center fb">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
                       <g id="Landing-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="01_Homepage-Copy" transform="translate(-1170, -4125)">
                         <g id="fb" transform="translate(1170, 4125)">
                          <rect id="Rectangle-Copy-16" x="0" y="0" width="16" height="16"/>
                          <path d="M6.22742111,8.04570185 L6.22742111,13.8433079 C6.22742111,13.921654 6.29270947,14 6.38411317,14 L8.53862894,14 C8.61697497,14 8.695321,13.9347116 8.695321,13.8433079 L8.695321,7.94124048 L10.2491839,7.94124048 C10.3275299,7.94124048 10.3928183,7.87595212 10.405876,7.79760609 L10.5364527,6.03482046 C10.5495103,5.94341676 10.4711643,5.86507073 10.3797606,5.86507073 L8.68226333,5.86507073 L8.68226333,4.61153428 C8.68226333,4.31120783 8.91730141,4.07616975 9.21762786,4.07616975 L10.4189336,4.07616975 C10.4972797,4.07616975 10.5756257,4.01088139 10.5756257,3.91947769 L10.5756257,2.15669206 C10.5756257,2.07834603 10.5103373,2 10.4189336,2 L8.38193689,2 C7.19368879,2 6.22742111,2.96626768 6.22742111,4.15451578 L6.22742111,5.86507073 L5.15669206,5.86507073 C5.07834603,5.86507073 5,5.93035909 5,6.02176279 L5,7.79760609 C5,7.87595212 5.06528836,7.95429815 5.15669206,7.95429815 L6.22742111,7.95429815 L6.22742111,8.04570185 Z" id="Path" fill="#9B9B9B"/>
                       </g>
                    </g>
                 </g>
              </svg>
              <p>Facebook</p>
           </a>
        </li>
        <li>
          <a href="" class="d-flex align-items-center">
             <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
               <title>A7C3EE45-6127-41CD-A789-9E32102BC142</title>
               <g id="Landing-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                 <g id="01_Homepage-Copy" transform="translate(-1170, -4161)">
                   <g id="linkedin" transform="translate(1170, 4161)">
                     <rect id="Rectangle-Copy-2" x="0" y="0" width="16" height="16"/>
                     <path d="M13,13 L13,9.01803156 C13,7.66532811 11.9034172,6.5687453 10.5507137,6.5687453 C9.91209617,6.5687453 9.16829452,6.959429 8.80766341,7.54545455 L8.80766341,6.71149512 L6.71149512,6.71149512 L6.71149512,13 L8.80766341,13 L8.80766341,9.29601803 C8.80766341,8.71750563 9.27347859,8.24417731 9.85199098,8.24417731 C10.4329066,8.24417731 10.9038317,8.71510244 10.9038317,9.29601803 L10.9038317,13 L13,13 M4.26972201,5.53193088 C4.96682072,5.53193088 5.53193088,4.96682072 5.53193088,4.26972201 C5.53193088,3.57099925 4.96844478,3 4.26972201,3 C3.56847391,3 3,3.56847391 3,4.26972201 C3,4.96844478 3.57099925,5.53193088 4.26972201,5.53193088 M5.31404959,13 L5.31404959,6.71149512 L3.23290759,6.71149512 L3.23290759,13 L5.31404959,13 Z" id="Combined-Shape" fill="#FFFFFF" fill-rule="nonzero"/>
                  </g>
               </g>
            </g>
         </svg>
         <p>Linkedln</p>
      </a>
   </li>
   <li>
    <a href="" class="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
     <title>14B35753-87A6-42EE-BAF1-05383B14108E</title>
     <g id="Landing-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="01_Homepage-Copy" transform="translate(-1170, -4196)">
       <g id="twitter" transform="translate(1170, 4196)">
        <rect id="Rectangle" x="0" y="0" width="16" height="16"/>
        <g transform="translate(3, 4)" fill="#9B9B9B" fill-rule="nonzero" id="Path">
         <path d="M11,0.995475113 C10.5951243,1.16968326 10.1586998,1.2841629 9.70650096,1.33891403 C10.1692161,1.07511312 10.5267686,0.657013575 10.6950287,0.154298643 C10.2586042,0.403167421 9.7748566,0.577375566 9.26481836,0.676923077 C8.84942639,0.248868778 8.26577438,0 7.60325048,0 C6.36759082,0 5.35803059,0.955656109 5.35803059,2.13529412 C5.35803059,2.30452489 5.3790631,2.46877828 5.41586998,2.62307692 C3.54397706,2.53348416 1.87715105,1.68235294 0.767686424,0.39321267 C0.573135755,0.70678733 0.462715105,1.07511312 0.462715105,1.46334842 C0.462715105,2.20497738 0.85707457,2.86199095 1.46701721,3.23529412 C1.09369025,3.23529412 0.74665392,3.13574661 0.4416826,2.98642534 C0.4416826,2.98642534 0.4416826,2.98642534 0.4416826,3.00135747 C0.4416826,4.03665158 1.21988528,4.90271493 2.25047801,5.09683258 C2.06118547,5.14660633 1.86137667,5.17149321 1.65630975,5.17149321 C1.51434034,5.17149321 1.37237094,5.15656109 1.23565966,5.13167421 C1.51959847,5.97285068 2.34512428,6.6 3.33891013,6.61493213 C2.57122371,7.19230769 1.59847036,7.53076923 0.536328872,7.53076923 C0.357552581,7.53076923 0.178776291,7.52081448 0,7.50090498 C0.999043977,8.1081448 2.1873805,8.46153846 3.45984704,8.46153846 C7.60325048,8.46153846 9.88001912,5.20633484 9.88001912,2.3841629 C9.88001912,2.28959276 9.88001912,2.2 9.87476099,2.10542986 C10.3164436,1.80678733 10.6950287,1.42850679 11,0.995475113 Z" style="fill: black;"/>
      </g>
   </g>
   </g>
   </g>
   </svg>
   <p>Twitter</p>
   </a>
   </li>
   <li>
    <a href="" class="d-flex align-items-center">
       <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
         <title>5413E7B1-F5A0-48A5-8559-5068FDD44FF8</title>
         <g id="Landing-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
           <g id="01_Homepage-Copy" transform="translate(-1170, -4233)">
             <g id="insta" transform="translate(1170, 4233)">
               <rect id="Rectangle-Copy-22" x="0" y="0" width="16" height="16"/>
               <path d="M5.48,2 L10.52,2 C12.44,2 14,3.56 14,5.48 L14,10.52 C14,12.4419509 12.4419509,14 10.52,14 L5.48,14 C3.56,14 2,12.44 2,10.52 L2,5.48 C2,3.55804907 3.55804907,2 5.48,2 M5.36,3.2 C4.16706494,3.2 3.2,4.16706494 3.2,5.36 L3.2,10.64 C3.2,11.834 4.166,12.8 5.36,12.8 L10.64,12.8 C11.8329351,12.8 12.8,11.8329351 12.8,10.64 L12.8,5.36 C12.8,4.166 11.834,3.2 10.64,3.2 L5.36,3.2 M11.15,4.1 C11.5642136,4.1 11.9,4.43578644 11.9,4.85 C11.9,5.26421356 11.5642136,5.6 11.15,5.6 C10.7357864,5.6 10.4,5.26421356 10.4,4.85 C10.4,4.43578644 10.7357864,4.1 11.15,4.1 M8,5 C9.65685425,5 11,6.34314575 11,8 C11,9.65685425 9.65685425,11 8,11 C6.34314575,11 5,9.65685425 5,8 C5,6.34314575 6.34314575,5 8,5 M8,6.2 C7.00588745,6.2 6.2,7.00588745 6.2,8 C6.2,8.99411255 7.00588745,9.8 8,9.8 C8.99411255,9.8 9.8,8.99411255 9.8,8 C9.8,7.00588745 8.99411255,6.2 8,6.2 Z" id="Shape-Copy" fill="#9B9B9B" fill-rule="nonzero"/>
            </g>
         </g>
      </g>
   </svg>
   <p>Instagram</p>
   </a>
   </li>
   </ul>
   </div>
   </div>
   </div>
   </footer>