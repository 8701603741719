<header class="pb-0">
    <app-header-details></app-header-details>
    <div class="container mt-4">
     <div class="row pagination">
       <div class="col-md-10">
          <ul class="list-unstyled d-flex align-items-center ">
             <li><a href="" class="text-white">Home &nbsp; &nbsp; >  &nbsp; &nbsp;</a></li>
             <li><a href="" class="text-white">&nbsp; &nbsp;Lows &nbsp; &nbsp; >&nbsp; &nbsp;</a></li>
             <li><a href="" class="text-white select">&nbsp; &nbsp;Belong Florida&nbsp; &nbsp;</a></li>
          </ul>
       </div>
    </div>

    <div class="row mt-2">
       <div class="col-md-12 d-flex align-items-start">
          <div class="logo-img prerty-img-top me-3">
             <img src="assets/img/property_detial_img.jpg" alt="" class="img-fluid">
          </div>
       </div>
    </div>
 </div>
</header>
<section class="section-padding details-page">
<div class="container">
  <div class="row">
    <div class="col-md-12">
        <h3 class="mt-4">Alabama Landlord Tenant Rights </h3>
        <p>Last Updated: <b>May 15, 2023</b></p>
        <p><a href="#">Alabama Code Title 35 Chapter 9A grants</a> rights and responsibilities to landlords and tenants whenever a written or oral rental agreement exists, or if payment is accepted as rent. For example, landlords have the right to timely rent payments, and tenants have the right to a livable dwelling.</p>

       <div class="content-links">
          <a href="#landlord-responsibilities" class="box-link">
             <span class="icon-landlord-responsibilities"></span>Landlord Responsibilities</a>
             <br>
          <a href="#tenant-responsibilities" class="box-link">
             <span class="icon-tenant-responsibilities"></span>Tenant Responsibilities</a><br>

          <a href="#evictions" class="box-link">
             <span class="icon-evictions"></span>Evictions</a><br>

          <a href="#security-deposits" class="box-link">
             <span class="icon-security-deposits"></span>Security Deposits</a><br>

          <a href="#lease-termination" class="box-link">
             <span class="icon-lease-termination"></span>Lease Termination</a><br>

          <a href="#rent-increases-fees" class="box-link">
             <span class="icon-rent-increases"></span>Rent Increases</a><br>

          <a href="#discrimination" class="box-link">
             <span class="icon-discrimination"></span>Discrimination</a><br>

          <a href="#landlord-right-to-entry" class="box-link">
             <span class="icon-landlord-entry"></span>Landlord Entry</a>
       </div>

       <p><b>Note:</b> These rights cannot be waived regardless of what the rental agreement says.</p>
       <div class="alert-modified alert-modified-info">
          <strong>Questions?</strong> To chat with an Alabama landlord tenant attorney, <a href="#" class="link-external" target="_blank">click here</a></div>

       <h3>Landlord Responsibilities in Alabama</h3>
       <p>In Alabama, landlords legally can’t rent property out unless it meets basic health and safety requirements. Here is a list of amenities and how they relate to Alabama’s habitability requirements:</p>

       <div class="table-responsive-wrap">
          <div class="table-responsive">
             <table class="table" style="width: 100%; height: 312px">
                <tbody>
                <tr style="height: 48px">
                <th style="height: 48px;width: 50.0%">Item</th>
                <th style="height: 48px;width: 25%.0">Has to Provide?</th>
                <th style="height: 48px;width: 25%.0">Has to Fix/Replace?</th>
                </tr>
                <tr style="height: 24px">
                <td style="height: 24px;width: 50%">Heating/AC</td>
                <td style="height: 24px;width: 25%">Only Heating</td>
                <td style="height: 24px;width: 25%">Yes</td>
                </tr>
                <tr style="height: 24px">
                <td style="height: 24px;width: 50%">Hot Water</td>
                <td style="height: 24px;width: 25%">Yes</td>
                <td style="height: 24px;width: 25%">Yes</td>
                </tr>
                <tr style="height: 24px">
                <td style="height: 24px;width: 50%">Kitchen Appliances</td>
                <td style="height: 24px;width: 25%">No</td>
                <td style="height: 24px;width: 25%">Only If Provided</td>
                </tr>
                <tr style="height: 24px">
                <td style="height: 24px;width: 50%">Garbage Containers/Removal</td>
                <td style="height: 24px;width: 25%">Yes</td>
                <td style="height: 24px;width: 25%">Yes</td>
                </tr>
                <tr style="height: 48px">
                <td style="height: 48px;width: 50%">Smoke and Carbon Monoxide (CO) Detectors</td>
                <td style="height: 48px;width: 25%">Smoke Only</td>
                <td style="height: 24px;width: 25%">Yes</td>
                </tr>
                <tr style="height: 24px">
                <td style="height: 24px;width: 50%">Mold</td>
                <td style="height: 24px;width: 25%">N/A</td>
                <td style="height: 24px;width: 25%">Yes</td>
                </tr>
                <tr style="height: 24px">
                <td style="height: 24px;width: 50%">Pest Control</td>
                <td style="height: 24px;width: 25%">N/A</td>
                <td style="height: 24px;width: 25%">Yes</td>
                </tr>
                </tbody>
             </table>
          </div>
       </div>

       <p>If a property doesn’t provide the legally required amenities for habitable housing, a tenant can usually report the landlord to government authorities for unsafe living conditions.</p>

       <a href="#" class="btn btn-primary">Read more</a>

       <h3 class="mt-5">Renter’s Rights for Repairs in Alabama</h3>
       <p>Landlords must perform necessary repairs in a timely manner. In Alabama, landlords must make repairs within 14 days after getting written notice from tenants.</p>
       <a href="#" class="btn btn-primary">Read more</a>

       <h3 class="mt-5">Tenant Responsibilities in Alabama</h3>
       <p>Tenants in Alabama are primarily responsible for:</p>

       <ul class="property-list-icon">
          <li>Keeping their residence clean and free from hazards and damages.</li>
          <li>Not disturbing other tenants.</li>
          <li>Not conducting illegal behaviors on the property.</li>
       </ul>

       <p>If landlords deem it necessary, tenants must clean or make repairs in 7 days. Alabama landlords can also give a 7-day notice to clean if tenants are violating the terms of the lease agreement.</p>

       <h3>Asset Management Services</h3>
       <p><strong>Supervision :</strong> Northpoint's hands-on management style attracts clients who don't want any nonsense in dealing with tenants. We provide constant supervision of the property managers and their activities to ensure high performance and compliance.</p>

       <p><strong>Analysis :</strong> We pay close attention to the profit and loss (operating) performance to creatively alter inefficient practices or uses of personnel, capital and/or material resources.</p>

       <p><strong>Management :</strong> We are experts in the assembly of onsite management teams, in the identification of inexpensive but quality third-party vendors and contractors, and in the formation of policy and procedure particular to any residential property.</p>

       <h3 class="mt-5">Tenant Responsibilities in Alabama</h3>
       <p>Tenants in Alabama are primarily responsible for:</p>

       <ul class="property-list-icon">
          <li><b>Keeping their residence</b> clean and free from hazards and damages.</li>
          <li><b>Not disturbing</b> other tenants.</li>
          <li><b>Not conducting illegal</b> behaviors on the property.</li>
       </ul>
       <h3 class="mt-5">Changing the Locks in Alabama</h3>
       <p><strong>Research :</strong> Provide up-to-date economic research of the local and national market trends. Strategically assess the short and long-term yield potential of each property.</p>
       
    </div>
 </div>
</div>
</section>

<app-newsletter></app-newsletter>

<app-footer></app-footer>