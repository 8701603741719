
<app-header-small></app-header-small>

<section class="state section-padding">
    <div class="container">
       <div class="row">
          <div class="col-md-6 px-lg-4">
             <div class="main-box">
                <div class="section-heading">
                   <h3>Find Property Managers <br> by <span>State</span></h3>
                </div>
                <ul class="state-select list-unstyled d-flex flex-wrap mb-0">
                   <li *ngFor="let state of states; let i = index"><p (click)="cityList(state.id,state.abbreviation)" class="common-state-class" [id]="'state_'+state.id" [ngClass]="{'state-active': i === 0}">{{state.abbreviation}}</p></li>
                </ul>
             </div>
          </div>
   
          <div class="col-md-6 px-lg-4">
             <div class="main-box city-box">
                <div class="section-heading">
                   <h3>Find Property Managers <br> by <span>City</span></h3>
                </div>
                <ul *ngIf="cities.length > 0" class="state-select2 list-unstyled d-flex flex-wrap">
                   <li *ngFor="let city of cities"><a routerLink="/agents/{{selectedState}}/{{city.city}}"><p>{{city.city}}</p></a></li>
                </ul>
                <div id="no-city-div" class="no-record" *ngIf="cities.length == 0">No cities available in {{selectedState}}</div>
                <!-- <div class="vew-all py-lg-4 text-center">
                   <a href="">View All Cities &nbsp; <img src="assets/img/arrow.png" class="img-fluid" width="8px" alt=""></a>
                </div> -->
             </div>
          </div>
       </div>
    </div>
   </section>

<app-newsletter></app-newsletter>
<hr>
<app-footer></app-footer>